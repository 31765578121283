import Plyr from 'plyr';

export const initPlyr = () => {

    let deco:boolean;
    let play:boolean;

    const playerElements:NodeListOf<HTMLElement> = document.querySelectorAll('.plyr');
    const players = [];

    playerElements.forEach(player => {
        let options = {
            controls: [
                // 'play-large',
                'play', 
                'progress', 
                'current-time',
                'mute',
                'volume',
                // 'captions', 
                // 'settings', 
                'pip', 
                'airplay', 
                'fullscreen'
            ],
            clickToPlay: true,
            ratio: null,
            fullscreen: {
                iosNative: true,
            },
            muted: false,
            autoplay: false,
            loop: {
                active: false,
            },
            vimeo: {
                dnt: true,
            },
        }

        play = false;
        deco = false;

        if (player.parentElement.dataset.initWithAutoplay) {
            play = true;
        } else {
            options.controls.push('play-large');
        }

        if (player.parentElement.dataset.decorationMode) {
            deco = true;
            options.controls = [];
            options.clickToPlay = false;
            options.muted = true;
            options.autoplay = true;
            options.loop.active = true;
        } else {
        }

        if (player.parentElement.dataset.aspectRatio != 'auto') {
            options.ratio = player.parentElement.dataset.aspectRatio.replace('/', ':');
        }

        let p = new Plyr(player, options);
        players.push(p);

        if (deco === true) {
            p.on('ready', () => {
                p.muted = true;
            })
        } else {
            p.on('ready', () => {
                p.muted = false;
            })
        }

        if (play) {
            p.play();
            p.volume = 1000;
        }
    })

    return players;
}
import Player from '@vimeo/player';

const options = {
    hover: {
        controls: false,
        playsinline: true,
        loop: true,
        muted: true,
        title: false,
        dnt: true,
        pip: false,
    },
    player: {
        controls: true,
        playsinline: false,
        loop: false,
        muted: false,
        title: true,
        dnt: true,
    }
}

export const initVimeo = () => {
    const hoverVideoContainers:NodeListOf<HTMLElement> = document.querySelectorAll('.c-hover-video_container');
    const videoContainers:NodeListOf<HTMLElement> = document.querySelectorAll('.s-video-player_container');
    
    videoContainers.forEach(container => {
        const player = new Player(container, {
            url: container.dataset.videoUrl, 
            ...options.player
        });
    
        container.addEventListener('click', e => {
            if (!container.parentElement.classList.contains('watching')) {
                playVideo();
            }
        })

        player.on('ended', () => {
            container.parentElement.classList.remove('watching');
        });

        player.on('loaded', function() {
            if (container.hasAttribute('data-autoplay') 
            && document.querySelector('html').classList.contains('swup-changed')) {
                playVideo();
            }
        })

        function playVideo() {
            container.parentElement.classList.add('watching');
            (container.children[0] as HTMLElement).focus();
            player.play();
        }

    })
    
    hoverVideoContainers.forEach(container => {
        const player = new Player(container, {url: container.dataset.videoUrl, ...options.hover});

        // force preload videos
        // player.play();
        // player.pause();

        player.on('bufferend', () => {
            container.classList.add('bufferend');
        });
    
        player.on('loaded', function() {
            let iframe = container.children[0];
            container.classList.add('loaded');
            container.classList.remove('is-loading');
            iframe.setAttribute('disablePictureInPicture', 'true');
            iframe.removeAttribute('title');
            if (container.classList.contains('scale-with-js') && !container.classList.contains('resized')) {
                let ratio = parseInt(container.dataset.w) / parseInt(container.dataset.h);
                resize(container, iframe, ratio);
            }
        })
    
        player.on('play', function() {
            container.classList.add('is-playing');
        });
    
        player.on('pause', function() {
            container.classList.remove('is-playing');
        });
    
        container.addEventListener('mouseenter', () => {
            if (!container.classList.contains('loaded')) container.classList.add('is-loading');
            player.play();
            let frame = container.children[0] as HTMLElement;
            frame?.setAttribute('title', '');
        })
        
        container.addEventListener('mouseleave', () => {
            player.pause();
            window.setTimeout(() => {
                player.setCurrentTime(0);
            }, 400)
        })
    });
}


function resize(container:any, video:any, ratio:any) {
    const w = container.clientWidth;
    const h = container.clientHeight; 
    const scale =  ((w / h) > ratio) ? (w / (ratio * h)) : (h * ratio / w);

    container.classList.add('resized');
    video.style.transform = 'scale(' + scale + ')';
}
export class Menu {
    button: HTMLButtonElement;
    element: HTMLElement;
    links: NodeListOf<HTMLAnchorElement>;

    constructor() {
        this.button = document.querySelector('#menu-button');
        this.element = document.querySelector('#menu-overlay');
        this.links = document.querySelectorAll('#menu-overlay .nav-link') ?? null;
        this.init();
    }

    isOpen():boolean {
        return document.body.classList.contains('is-menu-open');
    }

    close():void {
        document.body.classList.remove('is-menu-open');
        this.element.setAttribute('aria-hidden', 'true');
        this.element.setAttribute('tabindex', '-1');
    }

    toggle():void {
        if (this.isOpen()) {
            this.close();
        } else {
            document.body.classList.add('is-menu-open');
            this.element.setAttribute('aria-hidden', 'false');
            this.element.setAttribute('tabindex', '1');
        }
    }

    private init():void {
        this.button.addEventListener('click', e => {
            this.toggle();
        })
        this.button.addEventListener('mouseenter', e => {
            this.button.classList.add('is-hovered');
        })
    
        this.button.addEventListener('mouseleave', e => {
            this.button.classList.remove('is-hovered');
        })
        this.links.forEach(link => {
            link.addEventListener('click', () => {
                this.close();
            })
        })
    }
}
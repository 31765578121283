// const slugify = require('slugify');

function escapeHtml(unsafe)
{
    return unsafe
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

export default function initSearch(swup) {
    const searchContainer = document.querySelector('.search');
    if (!searchContainer) return;

    const searchInitButton = document.querySelector('.search_init-button');
    const searchParam = document.querySelector('.searchparam');
    const searchInputs = document.querySelector('.search_inputs');
    const searchTextInput = searchInputs.querySelector('input');
    searchInitButton.addEventListener('click', () => {
        searchContainer.classList.add('is-initialized');
        searchTextInput.focus();
        // searchParam.innerHTML = 'Search...';
    })
    searchInputs.addEventListener('focusout', () => {
        window.setTimeout(() => {
            searchContainer.classList.remove('is-initialized');
        }, 300)
    })
    searchInputs.addEventListener('submit', e => {
        e.preventDefault();
        
        let url = searchInputs.getAttribute('action');
        let val = encodeURIComponent(searchTextInput.value);
        searchTextInput.value != '' ? url += `/search:${val}` : null;

        swup.loadPage({url: url});
    })
}
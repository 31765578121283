export const initFilters = () => {
    let accordion = document.querySelectorAll('.accordion');
    if (accordion) {
        accordion.forEach(acc => {
            let button = acc.querySelector('button.toggle-accordion');
            if (!button) return;
            button.addEventListener('click', e => {
                if (acc.classList.contains('is-open')) {
                    acc.classList.remove('is-open');
                    // button.innerText = button.dataset.textWhenClosed;
                } else {
                    acc.classList.add('is-open');
                    // if (button.dataset.textWhenClosed.length == 0) {
                    //     button.dataset.textWhenClosed = button.innerText;
                    // }
                    // button.innerText = button.dataset.textWhenOpen;
                }
            })
        })
    }
}
import Marquee3k from 'marquee3000';

export const initMarqueeFunct = ():HTMLElement => {
    const marqueeElement = document.querySelector('.marquee3k');
    
    document.fonts.ready.then(function () {
        if (!marqueeElement) return;
        window.setTimeout(() => {
            Marquee3k.init();
        }, 500)
    });

    return <HTMLElement>marqueeElement;
}

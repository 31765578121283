export const watchViewport = () => {
    const selectors = [
        '[data-in-viewport]',
        '[data-type="grid-item-medium-text"] .text-wrapper'
    ]

    const inViewport = (entries, observer) => {
        entries.forEach(entry => {
            entry.target.classList.toggle("is-in-viewport", entry.isIntersecting);
        });
    };
      
    const Obs = new IntersectionObserver(inViewport);
    const obsOptions = {}; //See: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Intersection_observer_options
    let ELs_inViewport = [];
    
    selectors.forEach(s => {
        document.querySelectorAll(s).forEach(element => {
            ELs_inViewport.push(element);
        })
    })
    
    // Attach observer to every [data-inviewport] element:
    ELs_inViewport.forEach(EL => {
        Obs.observe(EL);
    });
}

import Swup from 'swup';
import SwupScrollPlugin from '@swup/scroll-plugin';
import SwupBodyClassPlugin from '@swup/body-class-plugin';
import SwupPreloadPlugin from '@swup/preload-plugin';
import SwupMorphPlugin from 'swup-morph-plugin';

import "@lottiefiles/lottie-player";

import {Menu} from './components/Menu';
import {initMarqueeFunct} from './components/marquee';
import {initVimeo} from './components/vimeo';
import {L} from './components/loadeer';
import {watchViewport} from './components/viewport';
import {initFilters} from './components/filters';
import { initPlyr } from './components/plyr';
import initSearch from './components/search';

const swup = new Swup({
    containers: ["#maincontent"],
    plugins: [
        new SwupScrollPlugin({
            doScrollingRightAway: false,
            animateScroll: false,
            scrollFriction: 0.3,
            scrollAcceleration: 0.04,
        }),
        new SwupPreloadPlugin(),
        new SwupMorphPlugin({
            containers: ['#menu-overlay'],
        }),
        new SwupBodyClassPlugin(),
    ],
});

const menu = new Menu();

swup.on('contentReplaced', () => {
    if (document.body.classList.contains('tmp-projects')) {
        let mainVideos:NodeListOf<HTMLElement> = document.querySelectorAll('.main-video .player-container');
        mainVideos.forEach(mainVideo => {
            mainVideo.setAttribute('data-init-with-autoplay', 'true');
        })
    }

    init();
    swup.scrollTo(0,0);

    // document.querySelector('html').classList.add('swup-changed');

});
swup.on('willReplaceContent', unload);

function init() {
    initMarqueeFunct();
    initVimeo();
    watchViewport();
    initFilters();
    L.observe();
    initPlyr();
    initSearch(swup);

    document.querySelector('.nav-link.is-active')?.addEventListener('click', () => {
        menu.close();
    })
}

document.querySelector('.logo').addEventListener('click', () => {
    menu.close();
})

function unload() {
    // if (menu.isOpen()) menu.toggle();
}

init();